.imgContainer1 {
  display: flex;
  /* opacity: 0.6; */
  /* height: 70vh; */
  height: 27rem;
  /* background-position: center; */
  background-color: black;
}

.imgContainer1 img {
  /* height: 70vh; */
  height: 27rem;
  width: 100%;
  background-position: center;

  object-fit: cover;
  opacity: 0.6;
}
.imgTextContainer {
  /* margin-top: 100px; */
  /* margin-left: 30px; */
  margin-left: 4.5%;
  /* margin-top: 50px; */
  margin-top: 4%;
  /* border-bottom: 1px solid red; */
  color: black;
  /* border-left: 2px red; */
}
.imgTextContainer h3 {
  color: black;
}
/* //////////////////////////////////////////////// */
.grid-Container {
  display: grid;
  grid-template-columns: auto auto;
  /* margin: 20px; */
  margin: 3.5%;
  align-content: space-between;
  /* background-color: green; */
  /* border: 2px solid red; */
}

.grid-Container img {
  border-radius: 50%;
  border-style: double;
  border-width: 7px;
  /* margin-top: 10px; */
  /* margin-left: 20px; */
  border-color: rgb(229, 212, 212);

  height: 8rem;
}
.grid-Container .text {
  /* margin-left: 50px; */

  /* background-color: blue; */
}
.grid-Container h4 {
  color: black;
  font-style: italic;
  font-weight: 700;
  /* margin-left: 20px; */
  margin-left: 1.9%;
  /* margin: 0; */
  /* margin-top: 10px; */
}
.grid-Container h5 {
  font-weight: 500;
  /* margin: 0; */
  margin-top: -20px;
  /* margin-left: 20px; */
  margin-left: 1.9%;
}
.grid-Container .text {
  /* margin-left: 50px; */

  /* background-color: blue; */
  /* margin-bottom: 50px; */
}
.ptext {
  display: flex;
  margin-top: 30px;
  margin-left: 0.2%;
  /* height: 10vh; */
  /* background-color: red; */
}
.ptext p {
  /* margin-top: 30px; */
  /* margin-top: 1%; */
}
/* p {
  text-align: start;

  margin-top: 20px;
  margin-left: -20px;
} */

/* /////////////////////////////////////////// */
@media screen and (max-width: 1100px) {
  /* .imgContainer img {
    height: 8.5rem;
  }

  .imgContainer img {
    height: 8.5rem;
  } */
}
@media screen and (max-width: 500px) {
  .imgContainer1 {
    height: 14.5rem;
  }
  .imgContainer1 img {
    height: 14.5rem;
  }

  #flex-Container {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
  }
  #flex-Container img {
    height: 6rem;
    text-align: center;
    /* align-content: center; */
  }
  #flex-Container .text {
    align-items: center;
  }
  .imgTextContainer h3 {
    margin-top: 13%;
    margin-left: 3.5%;
  }
  #flex-Container h4 {
    margin: 0;
    margin-left: 5.5%;
  }
  #flex-Container h5 {
    margin: 0;
    margin-left: 5.5%;
  }
  #flex-Container p {
    /* margin-top: 1px; */
  }
}
