.footerContainer {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  /* height: 60vh; */
  /* width: 100%; */
  background-color: rgb(25, 25, 25);
  /* padding-left: 20px; */
  padding-left: 3.8%;
}

.footeradressContainer h2 {
  color: rgb(255, 255, 255);
  text-align: start;

  /* background-color: red; */
}
.footeradressContainer p {
  color: rgb(255, 255, 255);
  text-align: start;
  margin-top: -6px;
  /* font-size: medium; */
}
h4 {
  /* margin-top: 40px; */
}
.footerIconsContainer {
  /* margin-top: -40px; */
  /* padding: 20px; */
  /* background-color: green; */
  /* margin-top: */
  /* text-align: start; */
}
.footerIconsContainer p {
  margin-top: -1px;
}

.footerIconsContainerIcons {
  display: grid;
  grid-template-columns: 0fr auto;
  /* grid-template-rows: auto auto; */
  /* margin-top: -10px; */
  /* align-content: space-between; */
  margin-top: -15px;
  padding: 0;
  /* background-color: aliceblue; */
}
/* .footerIconsContainerIcons p {
  margin: 0;
  padding: 0;
} */
/* ///////////////////////////////////////////////// */

/* .footerQuickLinks {
  background-color: black;
  height: 65vh;
  width: 20%;
} */
/* /////////////////////////// */
.footerQuickLinkstext {
  color: rgb(255, 255, 255);
  transition: all ease;
  text-align: start;
}
.footerQuickLinkstext p {
  text-align: start;
  /* font-size: medium; */
}
.footerQuickLinkstext .home:hover {
  color: rgb(239, 71, 75);
  cursor: pointer;
}
.footerQuickLinkstext .gallery:hover {
  color: rgb(239, 71, 75);
  cursor: pointer;
}
.footerQuickLinkstext .testimonial:hover {
  color: rgb(239, 71, 75);
  cursor: pointer;
}
.footerQuickLinkstext .bookAppointment:hover {
  color: rgb(239, 71, 75);
  cursor: pointer;
}

.footerQuickLinkstext .contact:hover {
  color: rgb(239, 71, 75);
  cursor: pointer;
}
a {
  color: inherit;
}
/* //////////////////////////////////// */
.footercoursestext1 {
  /* background-color: blue; */

  /* margin-top: -15px; */
  color: rgb(255, 255, 255);
}
.footercoursestext1 p {
  text-align: start;
  /* font-size: medium; */
}
.footercoursestext2 {
  /* background-color: magenta; */

  /* margin-top: 60px; */
  color: rgb(255, 255, 255);
}
.footercoursestext2 p {
  align-items: start;
  text-align: start;
  margin: 0;
  /* font-size: medium; */
  /* text-align: start; */
}
.footerCourses p {
  margin: 0;
  /* text-align: start; */
  /* font-size: medium; */
}
.footerQuickLinks h2,
.footerCourses h2,
.footercoursestext2 h2,
.footerIconsContainer h2 {
  color: rgb(239, 71, 75);
  font-weight: 400;
}

/* .footerQuickLinks h2 {
  color: rgb(239, 71, 75);
  font-weight: 400;
}
.footerCourses h2 {
  color: rgb(239, 71, 75);
  font-weight: 400;
}
footercoursestext2 h2 {
  color: rgb(239, 71, 75);
  font-weight: 400;
} */
.footerQuickLinks p {
  margin: 0;
  /* font-size: medium; */
  /* text-decoration: none; */
}
.enderFooter {
  background-color: rgb(9, 9, 9);
}
.ender {
  display: flex;
  flex-direction: row;
  color: rgb(255, 255, 255);
  background-color: rgb(9, 9, 9);
  /* background-color: red; */
  margin-left: 3.5%;
}
.ender p {
  /* margin-left: 20px; */
  /* margin-left: 4%; */
  /* margin-top: 25px; */
  /* font-size: medium; */
}
.power {
  padding-left: 26%;
}
/* p {
  margin: 20px;
} */
/* .imageContainer {
  height: 25vh;
} */
/* .imageContainer img {
  height: 25vh;
} */
/* .locator {
  margin-left: 150px;
  margin-top: -35px;
} */
/* //////////////////////////////// */
@media screen and (max-width: 1000px) {
  .footerContainer {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 500px) {
  .footerContainer {
    grid-template-columns: auto auto;
    /* align-items: center; */
    padding: 3px;
    /* text-align: center; */
    /* align-content: center; */
  }
  .footeradressContainer p {
    margin: 3px;
    font-size: medium;
  }
  .footerIconsContainer {
    align-content: space-between;
  }
  .footerIconsContainerIcons {
    grid-template-columns: auto auto auto auto;
    margin-top: 20px;
  }
  .footerIconsContainer p {
    margin-top: 10px;
    text-align: start;
    margin-left: 2px;
    font-size: medium;
  }
  .locator {
    text-align: center;
  }
  /* .footeradressContainer h2 {
    text-align: center;
  } */
}
