.imageHead {
  display: flex;
  /* height: 25rem; */
  width: 100%;
}
.imageHead img {
  /* height: 25rem; */
  width: 100%;
}

/* /////////////////////////////////// */
.gridContainer1 {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 4%;
}
.gridContainer1 h2 {
  margin: 0;
}
.gridContainer1img {
  /* height: 17rem; */
  width: 100%;
}
.gridContainer1img img {
  /* height: 17rem; */
  border-radius: 10px;
  width: 100%;
}
.gridContainer1Text {
  margin: 2%;
}
.gridContainer1Text p {
  text-align: start;
  margin-top: 1%;
}
.readMoreClassName {
  color: #004999;
  /* font-weight: 400; */
  /* color: red; */
  /* background-color: #ff0000; */
}
/*Grid container 2 ///////////////////////////////////// */

.text h2 {
  margin: 0;
  margin-left: 3.7%;
}
.gridContainer2 {
  display: grid;
  grid-template-columns: 24.3% 24.3% 24.3% 24.3%;
  margin-left: 3%;
}
.gridContainer2Items {
  margin: 2%;
}
.gridContainer2Items img {
  /* height: 11rem; */
  width: 100%;
  border-radius: 10px;
}

.gridContainer2Items p {
  margin-top: 1%;
}

/* ///Grid container 3/////////////////////////////////////// */
.gridContainer3Client {
  display: grid;
  grid-template-columns: 32.3% 32.3% 32.3%;
}

.gridContainer3ClientItems {
  margin-left: 11%;
}
.gridContainer3ClientItems img {
  /* height: 17rem; */
  width: 100%;
  border-radius: 10px;
}
@media screen and (max-width: 1000px) {
  .gridContainer1 {
    grid-template-columns: auto;
    align-content: center;
    /* width: 100%; */
    margin: 2%;
  }
  .gridContainer1img {
    /* height: 27rem; */
    margin: 2%;
    width: 100%;
  }
  .gridContainer1img img {
    /* height: 27rem; */
    width: 96%;
  }
  .gridContainer1Text {
    margin-left: 2.5%;
  }

  .gridContainer1Text p {
    margin: 0;
    /* font-size: small;
    font-weight: 650; */
  }
  .gridContainer2 {
    grid-template-columns: 50% 50%;
    /* width: 100%; */
    margin: 2%;
  }
  .gridContainer2Items {
    margin: 10px;
    padding: 0;
  }
  .gridContainer2Items img {
    /* height: 24rem; */
  }
  .gridContainer2Items p {
    text-align: start;
    margin: 10px;
    padding: 0;
  }
  br {
    display: none;
  }
  .gridContainer3Client {
    grid-template-columns: 50% 50%;
    margin: 2%;
  }
  .gridContainer3ClientItems {
    margin: 10px;
    padding: 0;
  }
  .gridContainer3ClientItems img {
    /* height: 20rem; */
  }
  .gridContainer3ClientItems p {
    text-align: start;
    margin: 10px;
    padding: 0;
  }
}
@media screen and (max-width: 500px) {
  .imageHead {
    height: 15rem;
    width: 100%;
  }
  .imageHead img {
    height: 15rem;
    width: 100%;
  }
  .gridContainer1 {
    grid-template-columns: auto;
    width: 100%;
    margin: 0;
  }

  .gridContainer1Text {
    margin: 10px;
  }
  .gridContainer1 h2 {
    margin: 0;
  }
  .gridContainer1 p {
    font-size: medium;
  }
  .gridContainer1img {
    /* height: 13rem; */
  }
  .gridContainer1img img {
    /* height: 13rem; */
  }
  /* ///////////////////////////// */
  .gridContainer2 {
    grid-template-columns: auto;
    /* width: 100%;
    margin: 10px;
    padding: 0;
    text-align: center; */
  }
  .gridContainer2Items {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .gridContainer2Items img {
    text-align: center;
    margin: 0;
    padding: 0;
    /* height: 17rem; */
  }
  .gridContainer2Items p {
    margin: 5px;
    padding: 0;
    text-align: start;
    font-size: medium;
  }
  .text h2 {
    margin-left: 10px;
    margin-top: 40px;
  }
  .gridContainer3Client {
    grid-template-columns: auto;
    margin: 0;
    padding: 0;
  }
  .gridContainer3ClientItems p {
    /* margin: 10px; */
    margin: 5px;
    padding: 0;
    text-align: start;
    font-size: medium;
  }
  /* .gridContainer3ClientItems img {
    height: 17rem;
    width: 100%;
    margin: 0;
    padding: 0;
  } */
  br {
    display: none;
  }
}
