.mailContainer {
  display: flex;
  /* margin-right: 30px; */
  color: rgb(239, 71, 75);
  transition: all ease;
  align-items: center;
  /* border-radius: 25px; */
  /* margin: 5px; */
  /* background-color: white; */
}

.mailContainer a {
  display: flex; /* add display flex to the anchor tag */
  align-items: center; /* align items vertically */
  color: inherit;
  text-decoration: none;
}

.mailContainer:hover {
  color: white;
  cursor: pointer;
}
