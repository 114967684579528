.facebookIcon {
  color: rgb(239, 71, 75);
  /* font-size: 17px; */

  /* margin-left: 40px; */
}
a {
  color: inherit;
}
.facebookIcon:hover {
  color: white;
  cursor: pointer;
}
