.book {
  background-color: rgb(239, 71, 75);
  color: rgb(255, 255, 255);
  transform: rotate(90deg);
  align-items: center;
  text-align: center;
  align-content: center;
  /* margin-left: 1050px; */
  position: fixed;
  /* padding: 4px; */
  border-radius: 10px;
  /* margin: 150px 0px 0px 1150px; */
  margin: 20% 0% 0% 91%;
  /* height: 25vh; */
  height: 10%;

  /* height: 9vh; */
  /* width: 15%; */
  width: 15%;
  /* justify-content: center; */
  transition: all ease;
}
.book p {
  padding-top: 3%;
  font-size: larger;
  /* margin: 5px; */
  /* padding: 4px; */
  /* background-color: black; */
  align-content: center;
  align-items: center;
  text-align: center;
  /* justify-content: center; */
}
.book:hover {
  color: rgb(25, 25, 25);
  cursor: pointer;
}

/* //////////////////////////////////////////////////// */

@media screen and (max-width: 1000px) {
  .book {
    /* margin: 100px 0px 0px 650px; */
    margin: 20% 0% 0% 85%;
    height: 5vh;
    transform: rotate(90deg);
    width: 25%;
    border-radius: 10px;
    font-size: medium;
  }
  p {
    font-size: larger;
    margin-top: 10px;
  }
}
@media screen and (max-width: 500px) {
  .book {
    /* margin: 100px 0px 0px 272px; */
    /* margin: 6.25rem 0rem 0rem 17rem; */
    margin: 20% 0% 0% 75%;
    /* height: 5vh; */
    height: 5%;
    transform: rotate(90deg);
    width: 45%;
    border-radius: 0;
  }
  .book p {
    /* font-size: small; */
    font-size: medium;
    margin-top: 5%;
  }
}
