h1 {
  text-align: center;
}
.grid-container {
  display: grid;
  height: 70vh;
  margin: 10px;
  /* padding: 10px; */
  grid-template-columns: auto auto auto;
  /* grid-template-rows: auto auto auto; */
}
.grid-Item1 {
  grid-row: span 2;
  grid-column: span 2;
  height: 30vh;
  margin-right: 40px;
}
.grid-Item1 p {
  /* height: 20vh; */
  text-align: start;
  margin-left: 75px;
}
.grid-Item2 {
  grid-row: span 3;
  /* grid-column: span 2; */
}
.grid-Item2 img {
  height: 70vh;
}
.grid-Item3 {
  /* grid-column: span 3; */
  width: 150%;
  /* margin-left: 75px; */
}
.grid-Item4 {
  width: 150%;
  margin-left: 100px;
}
.grid-Item5 {
  /* grid-column: span -1; */
  width: 150%;
  margin-left: 100px;
}

.containerfx {
  display: flex;
  flex-direction: row;
  /* background-color: green; */
  margin-left: 75px;
  /* align-content: space-between; */

  /* width: 194%; */
}
/* //////////////////////////////////////////////// */
/* .mainCoursesContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 20px;
} */
/* .mainCoursesContainer p {
  text-align: start;
} */
/* .coursesContainer {
  display: grid;
  grid-template-columns: auto auto auto;

  margin-top: -50px;
  padding: 0;
} */
.text {
  margin: 0;
  padding: 0;
  align-content: space-between;
}
.text p {
  text-align: start;
  margin-top: -23px;
  margin-left: 20px;
  padding: 0;
  font-size: small;
  font-weight: 700;
}
/* .abstractimg {
  height: 12rem;
  width: 100%;
  
} */
/* .abstractimg img {
  height: 12rem;
  width: 100%;
} */
/* //////////////////////////////////////////////////// */
.gridContainer {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* background-color: green; */
  padding: 2px;
  /* background-color: grey; */
  margin: 20px;
}

.gridItemContainer {
  background-color: rgb(244, 241, 241);
  margin: 2px;
}
.gridItemContainer img {
  height: 24rem;
  width: 100%;
}
.gridItemContainer h2 {
  text-align: center;
}
.gridItemContainer p {
  text-align: center;
}
.containerMobile {
  display: none;
}
/* /////////////////////////////////////////////////// */
/* @media screen and (max-width: 1680px) {
  .grid-Item2 img {
    height: 55vh;
    width: 100%;
     margin: 0; 
  }
  .containerfx {
    height: 33vh;
  }
  .gridContainer {
    grid-template-columns: auto auto auto auto;
  }
} */
/* /////////////////////////////////////////////////// */
@media screen and (max-width: 1000px) {
  .grid-container {
    margin: 0;
  }
  .grid-Item1 {
    /* background-color: green; */
    margin: 5px;
  }

  .grid-Item1 p {
    text-align: start;
    margin-left: 5px;
    margin: 0;
  }

  .grid-Item2 img {
    height: 30vh;
    /* margin: 0; */
  }
  .containerfx {
    display: grid;
    height: 30vh;
    grid-template-columns: auto auto auto;
    margin: 0;
    /* background-color: red; */
    width: 150%;
    /* margin-bottom: 20px; */
  }
  /*.grid-Item3 {
    width: 100%;
  }
  .grid-Item4 {
    width: 100%;
  }
  .grid-Item5 {
    width: 100%;
  } */
  .grid-container {
    display: grid;
    height: 70vh;
    margin: 10px;
    /* padding: 10px; */
    grid-template-columns: auto auto auto;
    /* grid-template-rows: auto auto auto; */
  }
  .gridContainer {
    grid-template-columns: auto auto;
    /* background-color: green; */
    padding: 1px;
    /* background-color: grey; */
    margin: 10px;
  }
}
/* ///////////////////////////////////////////////////// */
@media screen and (max-width: 500px) {
  h1 {
    font-size: large;
    font-weight: 800;
    margin: 0;
  }
  /* /////////////////////////////////// */
  .grid-container {
    display: none;
  }

  .grid1 p {
    display: none;
  }
  .grid-Item2 {
    display: none;
  }
  .containerfx {
    display: none;
  }
  .gridContainer {
    grid-template-columns: auto;
    margin: 0;
  }
  /* ////////////////////////////////// */
  .containerMobile {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  .containerMobile p {
    text-align: start;
    font-size: medium;
  }
  .containerMobile img {
    height: 50vh;
    width: 100%;
  }
  .mobileCourses {
    margin: 0;
  }

  .mobileCourses p {
    margin-top: -25px;
    margin-left: 20px;
    font-weight: 700;
    font-size: medium;
  }
  .mobileCoursesContainer1 {
    margin-top: 10px;
  }

  .mobileCoursesContainer2 {
    margin-top: 40px;
  }
  .mobileCoursesContainer3 {
    margin-top: 40px;
  }
  .gridItemContainer h2 {
    font-size: medium;
  }
  .gridItemContainer p {
    text-align: center;
    font-size: medium;
  }
}
