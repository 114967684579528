.gridcontainer {
  display: grid;
  /* background-color: rgba(0, 0, 255); */
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: 33% 33% 33%;
  /* height: 200rem; */
  /* height: 300px; */
  /* margin: 20px; */
  padding: 3.7%;
  /* margin: 2%; */
}
.readMoreClassName {
  color: #004999;
  /* font-weight: 400; */
  /* color: red; */
  /* background-color: #ff0000; */
}
.readMoreClassName:hover {
  color: initial;
}
.readLessClassName {
  color: #ff0000;
}
.readLessClassName:hover {
  color: initial;
}
.text {
  /* margin-top: 15%; */
  /* margin-left: 20px; */
}
.text h3 {
  /* margin: 30px; */
  /* margin: 0; */
  font-size: x-large;
  margin-left: 4.5%;
  margin-top: 4%;
}

.gridItem {
  /* background-color: green; */
  text-align: center;
  /* height: 43rem; */
  margin: 5px;
}
.gridItem img {
  width: 100%;
  /* height: 65vh; */
  /* height: 23rem; */
}
.gridItem p {
  color: black;
  /* background-color: red; */
  text-align: start;
}
@media screen and (max-width: 1000px) {
  .gridcontainer {
    grid-template-columns: auto auto;
  }
  .gridItem {
    margin: 5px;
  }
  .gridItem img {
    width: 100%;

    /* height: 23rem; */
  }
}
@media screen and (max-width: 500px) {
  .gridcontainer {
    grid-template-columns: auto;
  }
  .gridItem {
    margin: 0;
  }
  .gridItem img {
    width: 100%;

    /* height: 23rem; */
  }
  .gridItem p {
    font-size: medium;
  }
}
