.blinkerContainer {
  display: flex;
  background-color: rgb(0, 0, 0);
  height: 3.5rem;
  align-items: center;
}

.blinkerContainerText {
  color: "white";
  background-color: rgb(239, 71, 75);
  width: 20%;
}
.blinkerContainerText h2 {
  color: rgb(255, 255, 255);
  background-color: rgb(239, 71, 75);
  align-items: center;
  padding: 11px;
  margin: 0;
}
.type1 {
  color: rgb(255, 255, 255);
  margin: 50px;
  font-size: 1.25rem;
}
/* .blinkerContainerText p {
  align-items: center;
  text-align: center;
} */
/* @media screen and (max-width: 1472px) {
}
@media screen and (max-width: 1120px) {
} */
/* (max-width: 880px) */
@media screen and (max-width: 1000px) {
  .blinkerContainer {
    background-color: rgb(0, 0, 0);

    align-items: center;
  }
  .blinkerContainerText {
    width: 25%;
  }
  @media screen and (max-width: 500px) {
    .blinkerContainer {
      flex-direction: column;
      margin: 0;
      padding: 0;
      align-items: center;
    }
    .blinkerContainerText {
      width: 100%;
      margin: 0;
      padding: 0;
      align-items: center;
    }
    .blinkerContainerText h2 {
      font-size: medium;
      margin: 0;
      padding: 0;
      text-align: center;
    }
    .type1 {
      /* width: 50%; */
      margin: 5px;
      /* padding: 0; */
      width: 100%;
      font-size: small;
      /* text-align: center; */
      align-items: center;
    }
  }
}
