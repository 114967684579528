.headerGridContainer {
  display: grid;

  align-items: center;
  background-color: rgb(9, 9, 9);
  color: rgb(255, 255, 255);
  height: 3rem;
  /* grid-template-columns: auto auto 30px 30px 30px 30px 120px; */
  /* grid-template-columns: auto auto auto auto auto auto auto; */
  /* grid-template-columns: auto 830px 30px 30px 25px 25px 100px; */
  grid-template-columns: 15% 30% 49% 4% 4% 4% 4%;
}

.gridItemPhone {
  /* margin-left: 70px; */
  margin-left: 42%;
  /* background-color: white; */
}
.gridItemMail {
  /* margin-left: 10px; */
  margin-left: 0%;
  /* background-color: blue; */
}
.productIcon {
  /* background-color: red; */
  display: flex;
  justify-content: flex-end;
  /* width: 300%; */
}
.product {
  /* margin-left: 350%; */
  /* margin-right: -150%; */
  margin: 1%;
  /* background-color: blue; */
}
/* ///////////////////////////////////////////// */
/* @media screen and (max-width: 1472px) {
}
@media screen and (max-width: 1120px) {
} */
/* (max-width: 880px) */
@media screen and (max-width: 1100px) {
  .headerGridContainer {
    /* grid-template-columns: auto auto auto auto;
    align-content: space-between;
    align-items: center;
    align-content: center; */
    grid-template-columns: 17% 30% 49% 4% 4% 4% 4%;
  }

  /* .product {
    display: none;
  } */
  .gridItemPhone {
    /* margin-left: 30px; */
    /* margin-left: 10%; */
    /* font-size: small; */
    /* align-content: center; */
    /* margin-left: 13%; */
    margin-left: 20%;
    /* background-color: white; */
  }
  .gridItemMail {
    /* margin: 0; */
    /* font-size: small; */
    /* margin-left: 10%; */
    /* background-color: white; */
    margin-left: 0;
    /* justify-content: end; */
  }
}
/* ////////////////////////////////////// */
@media screen and (max-width: 500px) {
  .headerGridContainer {
    grid-template-columns: auto auto;
    align-content: space-between;
    align-content: center;
  }
  .product {
    display: none;
  }
  .gridItemPhone {
    /* font-size: small; */
    /* margin-left: 25px; */
    margin-left: 10%;
  }
  .gridItemMail {
    /* font-size: small; */
    margin-left: 5%;
  }
}
/* @media screen and (max-width: 500px) { */
/* .gridItemNone {
  display: none;
} */
@media screen and (max-width: 350px) {
  .gridItemPhone {
    font-size: small;
  }
  .gridItemMail {
    font-size: small;
  }
}
