body {
  /* background-color: grey; */
  margin: 0;
  padding: 0;
}

.mainContainer {
  margin: 38px;
  /* background-color: red; */

  /* padding: 40; */
  /* background-color: red; */
}
.mainContainer h2 {
  margin: 0;
  /* padding: 0; */
}
.mainContainer img {
  /* margin: 10; */
}
.mainItem {
  margin: 10px;
}
.slick-next {
  /* right: 10px; */
  right: 5px;
  background: rgb(243, 243, 243) !important;
  /* background: grey !important; */
  border-radius: 7px;
}

.slick-prev {
  /* left: 10px; */
  left: 5px;
  z-index: 2;
  background: rgb(243, 243, 243) !important;

  border-radius: 7px;
}

.slick-prev:before,
.slick-next:before {
  color: rgb(0, 0, 0) !important;
}
.containerItem img {
  /* height:350px /500px */
  height: 22rem;
  border-radius: 10px;
}
/* .mainContainer h2 {
    margin-left: 25px;
  } */
/* .containerItem {
    margin-left: 25px;
  }
  .containerItem h3 {
    margin-left: 25px;
  }
  h2 {
    margin-left: 25px;
  }
  .containerItem img {
    margin-left: 25px;
  } */
@media screen and (max-width: 1000px) {
  .containerItem img {
    /* height:350px /500px */
    /* height: 22rem; */
    height: 300px;

    border-radius: 10px;
  }
}
@media screen and (max-width: 500px) {
  .containerItem img {
    /* height:350px /500px */
    /* height: 22rem; */
    height: 500px;
    border-radius: 10px;
    /* width: 150%; */
  }
  /* .mainItem {
    margin: 0;
  } */
  .mainContainer {
    margin: 15px;
  }
  /* .mainContainer img {
    margin: 0;
  } */
  /* .containerItem {
    height: 650px;
    border-radius: 30px;
  } */
}
