.whatappIcon {
  font-size: 20px;
  /* margin-left: 12px; */
  color: rgb(239, 71, 75);

  transition: all ease;
}
.whatappIcon:hover {
  cursor: pointer;
  color: white;
}
