.phoneContainer {
  display: flex;
  color: rgb(239, 71, 75);
  /* margin-left: 15px; */
  align-items: center;
  transition: ease;
}
.phoneContainer a {
  display: flex; /* add display flex to the anchor tag */
  align-items: center; /* align items vertically */
  color: inherit;
  text-decoration: none;
}

.phoneContainer:hover {
  color: white;
  cursor: pointer;
}

/* p {
  color: white;
  transition: color pointer ease;
}
p:hover {
  color: red;
  cursor: pointer;
} */
